/* For desktop (greater than or equal to 992px width) */
@media (min-width: 992px) {
    .instruction {
      margin-top: 8%;
    }
  }
  
  /* For mobile (less than 992px width) */
  @media (max-width: 991px) {
    .instruction {
      margin-top: 30%;
    }
  }
  