.nav-menu{
    font-size: 18px;
    /* font-weight: bold; */
    padding: 0px 10px;
    color: black !important;
}
.nav-menu:hover{
    color: #ff6a3c !important;
}
.navbar-expand-md .navbar-nav {
    justify-content: center;
    width: 100%;
}
.align-nav-menu{
    /* padding-left: 35px; */
}

.nav-border{
    border-left: 1px solid #f4ece1;
}
.nav-style {
    padding: 0px 10px;
}
.nav-style a{
  font-size: 18px;
}
.brand-logo {
    width: 128px;
    height: 45px;
    object-fit: cover;
    margin-left: 30px;
}
.navbar-expand-md > .container-fluid{
    align-items: flex-end;
}

.Luxembourg-img{
    width: 55px;
    height: 44px;
    object-fit: cover;
}
.insta-img, .fb-img{
    width: 30px;
    height: 30px;
    object-fit: cover;
}

.become-sitter{
    color: #ff6a3c !important;
}

.nav-desktop-wrapper{
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 450px){
    .nav-border{
        border-left: none;
    }
    .align-nav-menu {
        padding-left: 25px;
    }
    .brand-logo {
        margin-left: 0px;
    }
}

@media only screen and (max-width: 768px){
    .mobnav-setup{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .navbar-toggler{
        width: 56px;
        height: 40px;
    }
    .nav-desktop-wrapper{
        display: unset;
    }
}