.twitter-bs-wizard .twitter-bs-wizard-nav {
    position: relative;
  }
  .twitter-bs-wizard .twitter-bs-wizard-nav:before {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #e3e6e8;
    position: absolute;
    left: 0;
    top: 26px;
  }
  .doc-view-btn{
      background-color: #fe7648 !important;
      color: white !important;
      font-family: Montserrat;
      padding: 10px !important;
      border-radius: 25px !important;
      font-size: 13px !important;
      font-weight: bold !important;
      /* width: 15%; */
      border: 0 !important;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
  
  .doc-view-btn:hover {
      background-color: #fe7648;
      color: #fff;
    }
  .twitter-bs-wizard .twitter-bs-wizard-nav .step-number {
    display: inline-block;
    width: 38px;
    height: 38px;
    line-height: 34px;
    border: 2px solid #fe7648;
    color: #fe7648;
    text-align: center;
    border-radius: 50%;
    position: relative;
    background-color: white;
  }
  @media (max-width: 991.98px) {
    .twitter-bs-wizard .twitter-bs-wizard-nav .step-number {
      display: block;
      margin: 0 auto 8px !important;
    }
  }
  .twitter-bs-wizard .twitter-bs-wizard-nav .nav-link .step-title {
    display: block;
    margin-top: 8px;
    font-weight: bold;
  }
  @media (max-width: 575.98px) {
    .twitter-bs-wizard .twitter-bs-wizard-nav .nav-link .step-title {
      display: none;
    }
  }
  .twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active {
    background-color: transparent;
    color: #fe7648;
  }
  .twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active .step-number {
    background-color: #fe7648;
    color: white;
  }
  .twitter-bs-wizard .twitter-bs-wizard-pager-link {
    padding-top: 24px;
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
  }
  .twitter-bs-wizard .twitter-bs-wizard-pager-link li {
    display: inline-block;
  }
  .twitter-bs-wizard .twitter-bs-wizard-pager-link li a {
    display: inline-block;
    padding: 0.47rem 0.75rem;
    background-color: black;
    color: white;
    border-radius: 0.25rem;
  }
  .twitter-bs-wizard .twitter-bs-wizard-pager-link li.disabled a {
    cursor: not-allowed;
    background-color: #939aa0;
  }
  .twitter-bs-wizard .twitter-bs-wizard-pager-link li.next {
    float: right;
  }
  .twitter-bs-wizard-tab-content {
    padding-top: 24px;
    min-height: 262px;
  }
  .nav-link{
    color:#aab0b5;
    cursor: pointer;
    font-family: Montserrat;
  }
  .nav-link:hover{
    color:#fe7648;
    cursor: pointer;
  }
 
  input, label{
    font-family: Montserrat;
    font-size: 14px;
  }
.Form-title{
    font-family: monopola;
}
.form-main{
    height: auto;
    background-color: #f4ece1;
}
.text-size{
  font-size: 11px;
}
.auth-body-bg {
  background-color: #f38221;
}
.authentication-bg {
  background-image: url("../../../../Assests/Images/header_pic.webp");
  height: 100vh;
  background-size: cover;
  background-position: center;
}
.authentication-bg .bg-overlay {
  background-color: #292626;
}
.tab-pane{
  height: 65vh !important;
  overflow-y: scroll !important;
  overflow: hidden;
}
.tab-pane::-webkit-scrollbar{
  display: none !important;
}
@media (max-width: 991px) {
  .authentication-bg {
    display: none;
  }
}
.authentication-page-content {
  height: 100vh;
  display: flex;
}
.auth-form-group-custom {
  position: relative;
}
.auth-form-group-custom .form-control {
  height: 60px;
  padding-top: 28px;
  padding-left: 60px;
}
.auth-form-group-custom label {
  position: absolute;
  top: 7px;
  left: 60px;
}
.auth-form-group-custom .auti-custom-input-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 19px;
  font-size: 24px;
  color: #f38221;
}
.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
  z-index: 9;
}
.login-btn {
  background-color: #f38221;
  color: white;
  border: 0;
}
.login-btn:hover {
  background-color: #f38221;
}



/* login css */
