.navbar-expand-lg .navbar-collapse {
    flex-direction: row-reverse;
}

.shadow{
    box-shadow: 0px -2px 14px 0px;
}
.navbar-expand-md .navbar-nav .dropdown-menu {
    right: 0% !important;
}
.nav-menu-login{
    justify-content: end !important;
}
.dropdown-toggle{
    color: black;
}
.dropdown {
    list-style: none;
}

@media (min-width: 768px) {
    .conditional-styles {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
  