.contain{
    background-color: white;
}
.form-check-input:checked{
    background-color: #fe7648;
    border-color: #fe7648;
}
.terms{
    cursor: pointer;
    text-decoration: underline;
    color: black;
}
.terms:hover{
    text-decoration: underline;
    color: black;
}
.start-application-wrapper{
    background-color: #f4ece1;
    padding: 40px;
    text-align: center;
    border-radius: 20px;
    width: 50% !important;
    margin-top: 100px;
}
.start-application-wrapper h1{
    font-family: monopola;
}

.start-application-wrapper p{
    font-family: Montserrat;
    margin: 20px 0px;
}
.parent-wrap{
    justify-content: center;
    align-items: center;
    padding: 25px 0px;
    height: 100vh;
}

.info-list{
    font-family: Montserrat;
    text-align: left;
}

.info-list li{
    margin: 5px 0px;
}

body{
    font-family: Montserrat;
}

@media only screen and (max-width: 450px){
    .start-application-wrapper {
        background-color: #f4ece1;
        padding: 10px;
        text-align: center;
        border-radius: 20px;
        width: 80% !important;
    }
}

@media (max-width: 1024px){
    .start-application-wrapper{
        width: 70% !important;
    }
}