.hamburger-icon-btn{
    background: #f4811f !important;
    padding: 8px 8px 8px 8px !important;
    width: 45px !important;
    border-radius: 3px !important;
    color: white !important;
}
.close-hamburger-icon{
    color: #f4811f !important;
    padding: 4px 8px 4px 8px !important;
    margin: 12px 12px 12px 12px !important;
    width: 45px !important;
    border-radius: 3px !important;
    background-color: #002842 !important;
}
.css-1808mag-MuiPaper-root-MuiAccordion-root.Mui-expanded{
    margin: 0 !important;
}
.css-1wqf3nl-MuiAccordionSummary-expandIconWrapper{
    width: 45px !important;
    border: 1px solid black !important;
    border-radius: 20px !important;
    display: flex;
    justify-content: center;
}