.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: 'Montserrat', sans-serif;
}

.custom-col {
  margin: 10px; /* Adjust the margin to control the gap */
}

.icon-container {
  width: 300px; /* Set the width and height to create a square container */
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 2px solid black; */
  border-radius: 10px;
  transition: border-color 0.3s ease-in-out;
  margin: 0 auto;
  text-align: center; /* Center the text within the icon-container */
}

@media (max-width: 767px) {
  .newhome {
    margin-top: 15%; /* Adjust the margin value as needed */
  }
}

.title {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1;
  display: flex;
  justify-content: center; /* Center the Navbar horizontally */
  align-items: center; /* Center the Navbar vertically */
}

.icon-container:hover {
  border-color: #f38221;
  cursor: pointer;
}

.custom-icon {
  max-width: 80%;
  max-height: 80%;
}



