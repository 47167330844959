.dynamic-btn{
    background-color: #fe7648 !important;
    color: white !important;
    font-family: Montserrat;
    padding: 10px !important;
    border-radius: 25px !important;
    font-size: 16px !important;
    font-weight: bold !important;
    /* width: 15%; */
    border: 0 !important;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

}

.dynamic-btn:hover {
    background-color: #fe7648;
    box-shadow: 0px 15px 20px rgb(112, 107, 107);
    color: #fff;
    transform: translateY(-3px);
  }