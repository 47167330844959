.login-sitter-btn{
    background: white;
    font-size: 14px;
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    font-family: "Roboto", Sans-serif;
    font-weight: bold;
    line-height: 100%;
}
.luxembourg-icon{
    max-width: 27%;
    margin: 0px 10px 6px 0px;
    padding: 0px 6px;
}
.user-profile-data{
    width: 17%;
    display: flex;
    justify-content: end;
    align-items: end;
}
.menu-header{
    display: flex;
    align-items: center;
    font-family: "Roboto", Sans-serif;
    font-weight: bold;
    position: relative;
    font-size: 15px;
    line-height: 1.2em;
    padding-inline: 12px;
    padding-block: 8px;
    border-radius: 10px 10px 10px 10px;
}
.menu-header:hover{
    background-color: #F58220;
    color: white;
    cursor: pointer;
}

.highlight li:first-child{
    color: #FF9315;
    background-color: #FFF4E5;
    cursor: pointer;
}

.menu-header:hover + .menu-header-dropdown ul{
    display: block;
}

.menu-header-dropdown ul:hover{
    display: block;
}

.menu-header p{
    margin: 0;
}

.menu-header-dropdown{
    position: absolute;
    background-color: #FCFCFC;
    border: 1px solid #F6F4F1;
    text-align: left;
    font-family: "Roboto", Sans-serif;

}
.menu-header-dropdown ul{
    display: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
}
.menu-header-dropdown li{
    color: #4D4D4D;
    list-style: none;
    padding-inline: 15px;
    padding-block: 5px;
}
.menu-header-dropdown li:hover{
    color: #FF9315;
    background-color: #FFF4E5;
    cursor: pointer;

}
.new-mobile-masthead{
    display: none;
}
.new-desktop-masthead{
    display: block;
} 

@media only screen and (max-width: 1024px){
    .new-desktop-masthead{
        display: none !important;
    }
    .new-mobile-masthead{
        display: block;
    }
}

@media only screen and (max-width: 1350px){
    .login-sitter-btn{
        font-size: 12px;
    }
}