@font-face {
  font-family: "Montserrat";
  src: url("/src/Assests/Fonts/Brandon\ Grot\ W01\ Light.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Smooch";
  src: url("/src/Assests/Fonts/Smooch-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "monopola";
  src: url("/src/Assests/Fonts/AmaticSC-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

.skiptranslate .VIpgJd-ZVi9od-ORHb-OEVmcd{
  display: none;
}

.translated-ltr body{
  position: static !important;
}