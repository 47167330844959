.fc .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 2px 4px;
    color: black;
    text-decoration: none;
}
.fc .fc-daygrid-day-number {
    position: relative;
    z-index: 4;
    padding: 4px;
    color: black;
    text-decoration: none;
}

.desktop-view{
    display: block;
    font-weight: 700;
}
.mobile-view{
    display: none;
    font-weight: 700;
}
.goog-te-combo{
    padding: 2px;
    font-family: 'Montserrat' !important;
    font-weight: bold;
}

.goog-te-gadget{
    visibility: hidden;
    display: flex;
    flex-direction: row;
}

.goog-te-gadget > span {
    display: none;
}

.goog-te-gadget > div {
    visibility: visible;
}

#google_translate_element{
    position: fixed;
    z-index: 100;
    right: 0;
}


@media only screen and (max-width: 450px){
    .desktop-view{
        display: none;
        font-weight: 700;
    }
    .mobile-view{
        display: block;
        font-weight: 700;
    }
}